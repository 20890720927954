module.exports = {
  pathPrefix: '',
  siteUrl: 'https://www.thinkbright.at',
  siteTitle: 'ThinkBright',
  siteDescription: 'Agile Coaching, Product Owner, Scrum Master, Business Analyse, Projektmanagement, IT und Unternehmensberatung',
  author: 'Gerald Drausinger',
  postsForArchivePage: 3,
  defaultLanguage: 'de',
  pages: {
    home: '/',
    contact: 'kontakt',
    agilescoaching: 'agiles-coaching',
    productowner: 'product-owner',
  },
  social: {
    rss: '/rss.xml',
  },
  contactFormUrl: process.env.CONTACT_FORM_ENDPOINT || 'https://getform.io/f/4eaf256b-9599-4356-8f51-02bdebf820bd',
  googleAnalyticTrackingId: process.env.GA_TRACKING_ID || '',
};
